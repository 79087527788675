import type { PolygonGeometry } from '@/components/soon-shared/util';

export interface ThermalZoneProperties {
  name: string;
  building_id: string;
  building_name: string;

  altitude: number;
  height: number;
  floor_count: number;
  mean_floor_height: number;

  gross_floor_area: number;
  usable_floor_area_ratio: number;
  usable_floor_area: number;

  usage_zone_code: UsageCode;
  performance: Performance;
  year: number | null;

  infiltration_rate: number;
  ventilation_system: VentilationSystemType;

  ExteriorFloor_U_value: number;
  ExteriorFloor_inertia: Inertia;
  ExteriorFloor_insulation: Insulation;

  ExteriorRoof_U_value: number;
  ExteriorRoof_inertia: Inertia;
  ExteriorRoof_insulation: Insulation;
  ExteriorRoof_window_type: string;
  ExteriorRoof_window_U_value: number;
  ExteriorRoof_window_solar_absorption: number;
  ExteriorRoof_window_transmission_factor: number;
  ExteriorRoof_window_share: number;

  ExteriorWall_U_value: number;
  ExteriorWall_inertia: Inertia;
  ExteriorWall_insulation: Insulation;
  ExteriorWall_window_type: string;
  ExteriorWall_window_U_value: number;
  ExteriorWall_window_solar_absorption: number;
  ExteriorWall_window_transmission_factor: number;

  ExteriorWall_window_share_N: number;
  ExteriorWall_window_share_NE: number;
  ExteriorWall_window_share_NW: number;
  ExteriorWall_window_share_S: number;
  ExteriorWall_window_share_SE: number;
  ExteriorWall_window_share_SW: number;
  ExteriorWall_window_share_E: number;
  ExteriorWall_window_share_W: number;

  blind_control_mode: BlindControlModeType;
  closed_blind_ratio: number;
  closing_deltaT: number;
  open_blind_ratio: number;
  temperature_blind_closed: number;
}

export interface ThermalZone {
  id: string; // study-local id like 'z_1'
  properties: ThermalZoneProperties;
  geometry: PolygonGeometry;
}

export interface ThermalZoneApi {
  getStudyThermalZones(studyId: number): Promise<ThermalZone[]>;
  persistStudyThermalZones(studyId: number, thermalZones: ThermalZone[]): Promise<void>;
}

export const usageCodeOptions = [
  'B00',
  'B01',
  'B02',
  'B03',
  'B04',
  'B05',
  'B06',
  'B07',
  'B08',
  'B09',
  'B10',
  'B11',
  'B12',
  'B13',
  'B14',
  'B15',
  'B16',
  'B18',
  'B19',
  'B20',
  'B21',
  'B22',
  'B23',
  'B25',
  'B26',
  'B27',
  'B28',
  'B29',
  'B30',
  'B31',
  'B33',
  'B34',
  'B35',
  'B36',
  'B37',
  'B39',
  'B40',
  'B43',
  'B44',
  'B46',
  'B47',
] as const;
export type UsageCode = (typeof usageCodeOptions)[number];

export const performanceOptions = [
  'UNDEFINED',
  '0-1938',
  '1939-1968',
  '1969-1975',
  '1976-1983',
  '1984-1996',
  '1997-2008',
  '2009-2014',
  '2015-2018',
  'E1',
  'E2',
  'E3',
  'E4',
] as const;
export type Performance = (typeof performanceOptions)[number];

export const inertiaOptions = ['low', 'mid', 'high'] as const;
export type Inertia = (typeof inertiaOptions)[number];

export const insulationOptions = ['indoor', 'outdoor'] as const;
export type Insulation = (typeof insulationOptions)[number];

export const windowTypeOptions = [
  'single_glazing',
  'double_glazing',
  'triple_glazing',
  'custom_glazing',
] as const;
export type WindowType = (typeof windowTypeOptions)[number];

export type GlazingType = {
  window_U_value: number;
  window_transmission_factor: number;
  window_solar_absorption: number;
};

export const windowTypeValues: Record<string, GlazingType> = {
  single_glazing: {
    window_U_value: 5.0,
    window_transmission_factor: 0.789,
    window_solar_absorption: 0.08,
  },
  double_glazing: {
    window_U_value: 1.8,
    window_transmission_factor: 0.7,
    window_solar_absorption: 0.15,
  },
  triple_glazing: {
    window_U_value: 0.8,
    window_transmission_factor: 0.6,
    window_solar_absorption: 0.3,
  },
} as const;

export const ventilationSystemTypeOptions = [
  'ZoneSingleFlowVentilation',
  'ZoneHeatRecoveryVentilation',
] as const;
export type VentilationSystemType = (typeof ventilationSystemTypeOptions)[number];

export const blindControlModeOptions = [0, 1, 2] as const;
export type BlindControlModeType = (typeof blindControlModeOptions)[number];
