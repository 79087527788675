import type { Scenario } from '../api-client';
import { usageCodeOptions } from '../thermal-zone/thermal-zone.interface';
import type { ScenarioApi, Scenarios } from './scenario.interface';

export class ScenarioApiMock implements ScenarioApi {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
  async getScenarios(withDefault: boolean, projectId?: number): Promise<Scenarios> {
    const defaultScenarios: Scenario[] = [];
    const userScenarios: Scenario[] = [];
    const projectScenarios: Scenario[] = [];

    const scenarioTemplates = [
      {
        category: 'water_flow',
        source: 'RT2012 - Industrie - 8h à 18h',
        unit: 'L/h/m2',
        default: true,
        display_property: 'relative',
        value_max: 0.001190476190476191,
      },
      {
        category: 'cooling_set_point',
        source: 'RT2012 - Enseignement - Université',
        unit: '°C',
        default: true,
        display_property: 'absolute',
        value_max: 1,
      },
      {
        category: 'heating_set_point',
        source: 'RT2012 - Enseignement secondaire (partie jour)',
        unit: '°C',
        default: true,
        display_property: 'absolute',
        value_max: 1,
      },
      {
        category: 'internal_gain_metabolism',
        source: 'RT2012 - Industrie - 8h à 18h',
        unit: 'W/m2',
        default: true,
        display_property: 'relative',
        value_max: 3.6,
      },
      {
        category: 'electric_load',
        source: 'RT2012 - Enseignement secondaire (partie jour)',
        unit: 'W/m2',
        default: true,
        display_property: 'relative',
        value_max: 5.140000000000001,
      },
      {
        category: 'air_change_rate_set_point',
        source: 'RT2012 - Enseignement - Université',
        unit: 'vol/h',
        default: true,
        display_property: 'relative',
        value_max: 1,
      },
    ];

    scenarioTemplates.forEach((template) => {
      usageCodeOptions.forEach((usageCode) => {
        defaultScenarios.push({
          ...template,
          _key: `${usageCode} ${template}`,
          code_typology: usageCode,
          scenario_name: usageCode,
        });
      });
    });

    return { default: defaultScenarios, user: userScenarios, project: projectScenarios };
  }
}
