import { AxiosError } from 'axios';
import {
  geoJSONCoordinatesToPolygonGeometry,
  polygonGeometryToGeoJSONCoordinates,
} from '@/util/geography';
import { type DefaultApi, type StudyStudyIdGeojsonGet200ResponseOneOf } from '../api-client';
import type {
  ThermalZoneApi,
  ThermalZone,
  ThermalZoneProperties,
} from './thermal-zone.interface';
import { ForbiddenAccessError, InvalidInputError, InvalidResponseError } from '../errors';

const rawThermalZoneProperties: Array<string> = [
  'name',
  'building_id',
  'building_name',
  'altitude',
  'height',
  'mean_floor_height',
  'floor_count',
  'gross_floor_area',
  'usable_floor_area_ratio',
  'usable_floor_area',
  'usage_zone_code',
  'performance',
  'year',
  'infiltration_rate',
  'ventilation_system',
  'ExteriorFloor_U_value',
  'ExteriorFloor_inertia',
  'ExteriorFloor_insulation',
  'ExteriorRoof_U_value',
  'ExteriorRoof_inertia',
  'ExteriorRoof_insulation',
  'ExteriorRoof_window_type',
  'ExteriorRoof_window_U_value',
  'ExteriorRoof_window_solar_absorption',
  'ExteriorRoof_window_transmission_factor',
  'ExteriorRoof_window_share',
  'ExteriorWall_U_value',
  'ExteriorWall_inertia',
  'ExteriorWall_insulation',
  'ExteriorWall_window_type',
  'ExteriorWall_window_U_value',
  'ExteriorWall_window_share',
  'ExteriorWall_window_solar_absorption',
  'ExteriorWall_window_transmission_factor',
  'blind_control_mode',
  'closed_blind_ratio',
  'closing_deltaT',
  'open_blind_ratio',
  'temperature_blind_closed',
  'is_initialized',
  'systems_0',
  'systems_1',
  'systems_2',
  'systems_3',
];

function validateId(id: string): void {
  const regex = /^[a-zA-Z]_\d+$/;
  if (!regex.test(id)) {
    throw new InvalidResponseError();
  }
}

function validateProperties(properties: any): ThermalZoneProperties {
  validateId(properties.building_id);
  validateId(properties.id);
  const validProperties: any = {};
  rawThermalZoneProperties.forEach((property) => {
    if (properties[property] !== undefined) {
      validProperties[property] = properties[property];
    } else {
      throw new InvalidResponseError();
    }

    const exteriorWallWindowShare = properties.ExteriorWall_window_share;
    delete validProperties.ExteriorWall_window_share;

    validProperties.ExteriorWall_window_share_N = exteriorWallWindowShare.N;
    validProperties.ExteriorWall_window_share_NE = exteriorWallWindowShare.NE;
    validProperties.ExteriorWall_window_share_NW = exteriorWallWindowShare.NW;
    validProperties.ExteriorWall_window_share_S = exteriorWallWindowShare.S;
    validProperties.ExteriorWall_window_share_SE = exteriorWallWindowShare.SE;
    validProperties.ExteriorWall_window_share_SW = exteriorWallWindowShare.SW;
    validProperties.ExteriorWall_window_share_E = exteriorWallWindowShare.E;
    validProperties.ExteriorWall_window_share_W = exteriorWallWindowShare.W;

    if (properties.performance === 'Indéfinie') {
      validProperties.performance = 'UNDEFINED';
    }
  });

  return validProperties;
}

function transformToRawProperties(
  thermalZoneId: string,
  properties: ThermalZoneProperties,
): object {
  const validProperties: any = { ...properties };

  validProperties.id = thermalZoneId;

  validProperties.ExteriorWall_window_share = {
    N: properties.ExteriorWall_window_share_N,
    NE: properties.ExteriorWall_window_share_NE,
    NW: properties.ExteriorWall_window_share_NW,
    S: properties.ExteriorWall_window_share_S,
    SE: properties.ExteriorWall_window_share_SE,
    SW: properties.ExteriorWall_window_share_SW,
    E: properties.ExteriorWall_window_share_E,
    W: properties.ExteriorWall_window_share_W,
  };

  delete validProperties.ExteriorWall_window_share_N;
  delete validProperties.ExteriorWall_window_share_NE;
  delete validProperties.ExteriorWall_window_share_NW;
  delete validProperties.ExteriorWall_window_share_S;
  delete validProperties.ExteriorWall_window_share_SE;
  delete validProperties.ExteriorWall_window_share_SW;
  delete validProperties.ExteriorWall_window_share_E;
  delete validProperties.ExteriorWall_window_share_W;

  if (validProperties.performance === 'UNDEFINED') validProperties.performance = 'Indéfinie';

  // We're not having initialization anymore state in our app thanks to our dynamic defaults selectors
  validProperties.is_initialized = true;

  // TODO Model these properties in ThermalZoneProperties
  validProperties.systems_0 = null;
  validProperties.systems_1 = null;
  validProperties.systems_2 = null;
  validProperties.systems_3 = validProperties.ventilation_system;

  return validProperties;
}

class ThermalZoneApiFacade implements ThermalZoneApi {
  // eslint-disable-next-line no-useless-constructor, no-empty-function
  constructor(private apiClient: DefaultApi) {}

  async getStudyThermalZones(studyId: number): Promise<Array<ThermalZone>> {
    try {
      const response = await this.apiClient.studyStudyIdGeojsonGet(studyId, true);

      if (response.data.status === false) {
        throw new InvalidInputError();
      }

      const rawThermalZones = (response.data as StudyStudyIdGeojsonGet200ResponseOneOf).data;

      if (rawThermalZones === undefined) {
        throw new InvalidResponseError();
      }

      const features = rawThermalZones.thermal_zone.features.map((feature) => ({
        id: feature.properties.id,
        properties: validateProperties(feature.properties),
        geometry: geoJSONCoordinatesToPolygonGeometry(
          feature.geometry.coordinates as Array<Array<[number, number]>>,
        ),
      }));
      return features;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status === 403) {
        throw new ForbiddenAccessError();
      } else {
        throw new InvalidResponseError();
      }
    }
  }

  async persistStudyThermalZones(studyId: number, thermalZones: ThermalZone[]): Promise<void> {
    const response = await this.apiClient.studyStudyIdUpdatePut(studyId, {
      geojson_zone: {
        type: 'FeatureCollection',
        crs: {
          properties: {
            name: 'epsg:4326',
          },
          type: 'name',
        },
        features: thermalZones.map((thermalZone) => {
          return {
            type: 'Feature',
            properties: transformToRawProperties(thermalZone.id, thermalZone.properties),
            geometry: {
              type: 'Polygon',
              coordinates: polygonGeometryToGeoJSONCoordinates(thermalZone.geometry),
            },
          };
        }),
      },
    });

    if (response.data.status === false) {
      throw new InvalidInputError();
    }
  }
}

const _testSubjects = {
  validateProperties,
  validateId,
  transformToRawProperties,
};

export { _testSubjects, ThermalZoneApiFacade };
